// src/Components/Hero.js
import React, { useState, useCallback, memo } from 'react';
import { Box, Heading, Text, Button, Icon, VStack, Flex, Input, useDisclosure, useColorModeValue, Container } from '@chakra-ui/react';
import { keyframes } from '@emotion/react'; // Import keyframes separately
import { Link, useNavigate } from 'react-router-dom';
import { FaRocket, FaComments, FaRobot, FaUser, FaMagic, FaStar } from 'react-icons/fa';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const API_URL = process.env.REACT_APP_API_URL || 'https://sbscoaches.com/api';

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0px rgba(29, 161, 242, 0.7); }
  100% { box-shadow: 0 0 0 20px rgba(29, 161, 242, 0); }
`;

const Message = memo(({ msg, renderIcon, msgBgColor, textColor }) => (
  <Flex
    justify={msg.role === 'user' ? 'flex-end' : 'flex-start'}
    mb={4}
  >
    <Box
      maxW="70%"
      bg={msg.role === 'user' ? 'purple.500' : msgBgColor}
      color={msg.role === 'user' ? 'white' : textColor}
      borderRadius="lg"
      px={3}
      py={2}
      animation={`${pulseAnimation} 1s ease-out`}
    >
      <Flex align="center" mb={2}>
        {renderIcon(msg.role)}
        <Text ml={2} fontWeight="bold">
          {msg.role === 'user' ? 'You' : 'AI Assistant'}
        </Text>
      </Flex>
      <Text>{msg.content}</Text>
    </Box>
  </Flex>
));

const Hero = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const [messages, setMessages] = useState([
    { role: 'assistant', content: "Hello! I'm your AI assistant for Sole Business Solutions. How can I help you today?" }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [remainingChats, setRemainingChats] = useState(5);

  const bgColor = useColorModeValue('white', 'gray.800');
  const msgBgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const gradientBg = "linear(to-r, purple.500, pink.500)";

  const handleInputChange = useCallback((e) => {
    setInputMessage(e.target.value);
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (inputMessage.trim() && remainingChats > 0) {
      const newMessage = { role: 'user', content: inputMessage };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setInputMessage('');
      setIsLoading(true);

      try {
        const response = await axios.post(`${API_URL}/landing-chat`, {
          messages: [...messages, newMessage].map(m => ({ role: m.role, content: m.content }))
        });

        const aiResponse = { role: 'assistant', content: response.data.message };
        setMessages(prevMessages => [...prevMessages, aiResponse]);

        const newRemainingChats = remainingChats - 1;
        setRemainingChats(newRemainingChats);

        // Check if the AI's message suggests signing up
        if (aiResponse.content.toLowerCase().includes('sign up') || 
            aiResponse.content.toLowerCase().includes('signup') ||
            newRemainingChats === 0) {
          // Short delay before redirecting to allow the user to read the message
          setTimeout(() => navigate('/signup'), 2000);
        }

      } catch (error) {
        console.error('Error sending message:', error);
        if (error.response && error.response.status === 429) {
          setMessages(prev => [...prev, { role: 'assistant', content: error.response.data.message }]);
          setRemainingChats(0);
          // Redirect to signup page on rate limit error
          setTimeout(() => navigate('/signup'), 2000);
        } else {
          setMessages(prev => [...prev, { role: 'assistant', content: "Sorry, I'm having trouble responding right now. Please try again later." }]);
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [inputMessage, remainingChats, messages, navigate]);

  const renderIcon = useCallback((role) => {
    if (role === 'user') return <Icon as={FaUser} w={6} h={6} color="blue.500" />;
    return <Icon as={FaRobot} w={6} h={6} color="purple.500" />;
  }, []);

  const ChatWindow = (
    <Box
      w="100%"
      h="100%"
      bg={bgColor}
      borderRadius="lg"
      overflow="hidden"
      boxShadow="xl"
      position="relative"
      borderWidth={2}
      borderColor="purple.500"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        bgGradient={gradientBg}
        p={4}
        borderBottomWidth={1}
        borderColor="purple.600"
      >
        <Icon as={FaComments} w={8} h={8} color="white" mr={2} />
        <Heading size="md" color="white">AI Assistant</Heading>
      </Flex>
      <VStack h="calc(100% - 130px)" spacing={4} p={4}>
        <Box flex={1} w="full" overflowY="auto">
          {messages.map((msg, index) => (
            <Message key={index} msg={msg} renderIcon={renderIcon} msgBgColor={msgBgColor} textColor={textColor} />
          ))}
        </Box>
      </VStack>
      <Box p={4}>
        {remainingChats > 0 ? (
          <Flex>
            <Input
              flex={1}
              value={inputMessage}
              onChange={handleInputChange}
              placeholder="Ask a question..."
              borderRadius="full"
              mr={2}
              _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px purple.500' }}
            />
            <Button
              onClick={handleSendMessage}
              bgGradient={gradientBg}
              color="white"
              borderRadius="full"
              _hover={{ bgGradient: "linear(to-r, purple.600, pink.600)" }}
              isLoading={isLoading}
            >
              <Icon as={FaMagic} />
            </Button>
          </Flex>
        ) : (
          <Button
            w="full"
            colorScheme="purple"
            onClick={() => navigate('/signup')}
          >
            Sign Up to Continue Chatting
          </Button>
        )}
        <Text fontSize="sm" textAlign="center" mt={2}>
          Remaining chats: {remainingChats}
        </Text>
      </Box>
      <Icon
        as={FaStar}
        position="absolute"
        top={2}
        right={2}
        color="yellow.400"
        w={6}
        h={6}
      />
    </Box>
  );

  return (
    <Box bg="gray.100" pt={{ base: 20, md: 32 }} pb={10}> {/* Increased top padding */}
      <Container maxW="7xl" px={4}>
        <Flex direction={{ base: "column", lg: "row" }} alignItems="stretch" justifyContent="space-between">
          <Box mb={{ base: 8, lg: 0 }} maxW={{ lg: "50%" }}>
            <VStack align={{ base: "center", lg: "flex-start" }} spacing={6}>
              <Icon as={FaRocket} w={14} h={14} color="purple.500" />
              <Heading as="h1" size="2xl" textAlign={{ base: "center", lg: "left" }}>
                Unlock Your Full Potential with Techniculture
              </Heading>
              <Text fontSize="xl" textAlign={{ base: "center", lg: "left" }}>
                Techniculture is the synergy of cutting-edge technology and our passion for beauty, revolutionizing how we work, grow, and connect. Sole Business Solutions is the ultimate platform for beauty professionals to streamline their business, attract more clients, and boost their revenue.
              </Text>
              <VStack spacing={4} align={{ base: "center", lg: "flex-start" }}>
                <Link to="/signup">
                  <Button colorScheme="purple" size="lg">
                    Subscribe Here
                  </Button>
                </Link>
                <Text fontSize="md">
                  Current Members Sign In Below
                </Text>
                <Link to="/login">
                  <Button variant="outline" colorScheme="purple" size="md">
                    Sign In
                  </Button>
                </Link>
                <Button
                  leftIcon={<FaComments />}
                  colorScheme="pink"
                  onClick={onOpen}
                  display={{ base: "flex", lg: "none" }}
                >
                  Chat with AI Assistant
                </Button>
              </VStack>
            </VStack>
          </Box>
          <Box
            display={{ base: "none", lg: "block" }}
            w={{ lg: "45%" }}
            h={{ lg: "600px" }}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="xl"
          >
            {ChatWindow}
          </Box>
        </Flex>
      </Container>

      {/* Modal for mobile view */}
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={0}>
            {ChatWindow}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Hero;
