import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Button,
  Text,
  Flex,
  Icon,
  useColorModeValue,
  useToast,
  useMediaQuery,
  Collapse,
  IconButton,
} from '@chakra-ui/react';
import { FaRobot, FaUser, FaKeyboard, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';

const API_URL = process.env.REACT_APP_API_URL || 'https://sbscoaches.com/api';

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const chatEndRef = useRef(null);
  const toast = useToast();
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  const bgColor = useColorModeValue('white', 'gray.800');
  const msgBgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const gradientBg = "linear(to-r, purple.500, pink.500, white)";

  // Use the AuthContext to get the user data
  const { user } = useAuth();
  const businessCategory = user?.business_category || 'beauty business';

  // Step 1: Add console.log statements here
  console.log('User:', user);
  console.log('Business Category:', businessCategory);

  // Step 2: Update the useEffect hook
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async () => {
    if (inputMessage.trim()) {
      const newMessage = { role: 'user', content: inputMessage };
      setMessages([...messages, newMessage]);
      setInputMessage('');
      setIsLoading(true);

      try {
        const response = await axios.post(`${API_URL}/chat`, {
          messages: [...messages, newMessage],
          businessCategory: businessCategory
        }, {
          withCredentials: true
        });

        const aiResponse = { role: 'assistant', content: response.data.message };
        setMessages(prevMessages => [...prevMessages, aiResponse]);
      } catch (error) {
        console.error('Error sending message:', error);
        toast({
          title: 'Error',
          description: 'Failed to get response from the AI.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderIcon = (role) => {
    if (role === 'user') return <Icon as={FaUser} w={isMobile ? 4 : 6} h={isMobile ? 4 : 6} color="blue.500" />;
    return <Icon as={FaRobot} w={isMobile ? 4 : 6} h={isMobile ? 4 : 6} color="cyan.500" />;
  };

  return (
    <Box width="100%">
      {/* Toggle Button */}
      <Flex justifyContent="flex-end" mb={2}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          size="md"
          colorScheme="purple"
          borderRadius="full"
          leftIcon={<FaKeyboard />}
        >
          {isOpen ? 'Close Chat' : 'Chat with AI'}
        </Button>
      </Flex>

      {/* Chat Interface */}
      <Collapse in={isOpen} animateOpacity>
        <Box 
          bg={bgColor}
          borderRadius="lg"
          boxShadow="xl"
          maxHeight="400px"
          overflow="hidden"
        >
          {/* Messages Area */}
          <Box 
            maxHeight="320px"
            overflowY="auto"
            p={3}
          >
            {messages.map((msg, index) => (
              <Flex
                key={index}
                justify={msg.role === 'user' ? 'flex-end' : 'flex-start'}
                mb={2}
              >
                <Box
                  maxW="80%"
                  bg={msg.role === 'user' ? 'purple.500' : msgBgColor}
                  color={msg.role === 'user' ? 'white' : textColor}
                  borderRadius="lg"
                  px={3}
                  py={2}
                >
                  <Flex align="center" mb={1}>
                    {renderIcon(msg.role)}
                    <Text ml={2} fontSize="xs" fontWeight="bold">
                      {msg.role === 'user' ? 'You' : 'AI Coach'}
                    </Text>
                  </Flex>
                  <Text fontSize="sm">{msg.content}</Text>
                </Box>
              </Flex>
            ))}
            <div ref={chatEndRef} />
          </Box>

          {/* Input Area */}
          <Flex p={3} borderTop="1px" borderColor="gray.200">
            <Input
              flex={1}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Type to chat..."
              size="sm"
              mr={2}
              borderRadius="full"
              _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px purple.500' }}
            />
            <Button
              onClick={handleSendMessage}
              bgGradient={gradientBg}
              color="white"
              borderRadius="full"
              size="sm"
              isLoading={isLoading}
            >
              <Icon as={FaKeyboard} />
            </Button>
          </Flex>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ChatComponent;
