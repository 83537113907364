import React, { useState, useRef, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import NiceAvatar, { genConfig } from 'react-nice-avatar';
import {
  Box,
  Button,
  VStack,
  HStack,
  Text,
  Input,
  Select,
  Flex,
  Center,
} from '@chakra-ui/react';

const ProfilePictureSelector = ({ currentAvatar, onAvatarChange }) => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [avatarType, setAvatarType] = useState('styled');
  const [avatarConfig, setAvatarConfig] = useState(genConfig());
  const editorRef = useRef(null);

  useEffect(() => {
    if (currentAvatar) {
      try {
        const parsedAvatar = JSON.parse(currentAvatar);
        setAvatarType('styled');
        setAvatarConfig(parsedAvatar);
      } catch {
        setAvatarType('custom');
        setImage(currentAvatar);
      }
    }
  }, [currentAvatar]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageUrl(URL.createObjectURL(file));
      setAvatarType('custom');
    }
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append('avatarType', avatarType);
    if (avatarType === 'custom' && editorRef.current) {
      editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
        formData.append('avatar', blob, 'avatar.png');
        onAvatarChange(formData);
      }, 'image/png', 0.9);
    } else if (avatarType === 'styled') {
      formData.append('avatarOptions', JSON.stringify(avatarConfig));
      onAvatarChange(formData);
    }
  };

  const handleAvatarConfigChange = (option, value) => {
    setAvatarConfig((prev) => ({ ...prev, [option]: value }));
  };

  const generateRandomAvatar = () => {
    setAvatarConfig(genConfig());
  };

  return (
    <VStack spacing={6} align="stretch">
    {/* Temporarily removed photo option
      <HStack justifyContent="center">
        <Button
          onClick={() => setAvatarType('custom')}
          colorScheme={avatarType === 'custom' ? 'blue' : 'gray'}
        >
          Use Photo
        </Button>
        <Button
          onClick={() => setAvatarType('styled')}
          colorScheme={avatarType === 'styled' ? 'blue' : 'gray'}
        >
          Create Styled Avatar
        </Button>
      </HStack>
    */}
    {/* Temporarily removed custom photo upload
      {avatarType === 'custom' && (
        <Box>
          <Input type="file" accept="image/*" onChange={handleImageUpload} mb={4} />
          <Center>
            {image && (
              <AvatarEditor
                ref={editorRef}
                image={imageUrl || image}
                width={250}
                height={250}
                border={50}
                scale={1.2}
              />
            )}
          </Center>
        </Box>
      )}
*/}
        <Box>
          <Flex justifyContent="center" mb={4}>
            <NiceAvatar style={{ width: '200px', height: '200px' }} {...avatarConfig} />
          </Flex>
          <Button onClick={generateRandomAvatar} mb={4} width="full">Generate Random Avatar</Button>
          <VStack align="stretch" spacing={3}>
            <Text fontWeight="bold">Customize your avatar:</Text>
            <Select onChange={(e) => handleAvatarConfigChange('sex', e.target.value)}>
              <option value="neutral">Neutral</option>
              <option value="masc">Masculine</option>
              <option value="fem">Feminine</option>
            </Select>
            <Select onChange={(e) => handleAvatarConfigChange('faceColor', e.target.value)}>
              <option value="#F9C9B6">Light</option>
              <option value="#AC6651">Medium</option>
              <option value="#5D4037">Dark</option>
            </Select>
            <Select onChange={(e) => handleAvatarConfigChange('hairColor', e.target.value)}>
              <option value="#000000">Black</option>
              <option value="#A52A2A">Brown</option>
              <option value="#FFD700">Blonde</option>
              <option value="#FF4500">Red</option>
              <option value="#808080">Gray</option>
              <option value="#C71585">Pink</option>
              <option value="#4B0082">Purple</option>
            </Select>
            <Select onChange={(e) => handleAvatarConfigChange('hairStyle', e.target.value)}>
              <option value="normal">Short</option>
              <option value="thick">Medium</option>
              <option value="mohawk">Long</option>
              <option value="womanLong">Braided</option>
              <option value="womanShort">Curly</option>
            </Select>
            {/* Add more Select components for other avatar options as needed */}
          </VStack>
        </Box>
      <Button onClick={handleSave} colorScheme="blue" size="lg" mt={4}>
        Save Avatar
      </Button>
    </VStack>
  );
};

export default ProfilePictureSelector;
