import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';

const ModernCalendar = ({ setShowCalendar }) => {
  const [monthIndex, setMonthIndex] = useState(new Date().getMonth());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const beautyEvents = {
    '01-01': {
      title: "New Year's Day",
      content: "Share transformations, fresh start packages, beauty resolutions guide",
      hashtags: "#NewYearNewLook #BeautyResolutions #2025Beauty"
    },
    '01-08': {
      title: "Clean Off Your Desk Day",
      content: "Organized station photos, cleaning tips",
      hashtags: "#SalonOrganization"
    },
    '01-15': {
      title: "National Hat Day",
      content: "Hat-friendly hairstyles, winter hair care",
      hashtags: "#HatHair #WinterHair"
    },
    '01-19': {
      title: "National Popcorn Day",
      content: "Salon snacks, client experience",
      hashtags: "#SalonLife"
    },
    '01-21': {
      title: "National Hugging Day",
      content: "Client appreciation content",
      hashtags: "#SalonLove"
    },
    '01-24': {
      title: "National Compliment Day",
      content: "Client transformations, positive vibes",
      hashtags: "#BeautyConfidence"
    },
    '01-28': {
      title: "Data Privacy Day",
      content: "Client trust and privacy practices",
      hashtags: "#SalonTrust"
    },

    // February
    '02-04': {
      title: "World Cancer Day",
      content: "Support services, wig styling tips",
      hashtags: "#BeautySupport"
    },
    '02-07': {
      title: "Send a Card Day",
      content: "Client thank you notes, loyalty rewards",
      hashtags: "#ClientAppreciation"
    },
    '02-13': {
      title: "Galentine's Day",
      content: "Friend group packages, spa parties",
      hashtags: "#GalentinesDay"
    },
    '02-14': {
      title: "Valentine's Day",
      content: "Romantic styles, couples packages",
      hashtags: "#ValentinesBeauty"
    },
    '02-17': {
      title: "Random Acts of Kindness",
      content: "Client surprises, community service",
      hashtags: "#SalonKindness"
    },
    '02-20': {
      title: "Love Your Pet Day",
      content: "Pet-friendly salon features",
      hashtags: "#SalonPets"
    },

    // March
    '03-08': {
      title: "International Women's Day",
      content: "Women empowerment stories, female entrepreneur tips, team celebration",
      hashtags: "#WomenInBusiness #BeautyBoss #SalonLife"
    },
    '03-14': {
      title: "World Sleep Day",
      content: "Overnight beauty treatments",
      hashtags: "#BeautyRest"
    },
    '03-19': {
      title: "Let's Laugh Day",
      content: "Fun salon moments, team culture",
      hashtags: "#SalonFun"
    },
    '03-20': {
      title: "Spring Equinox",
      content: "Spring trends and colors",
      hashtags: "#SpringBeauty"
    },
    '03-30': {
      title: "Take a Walk Day",
      content: "Outdoor photoshoot tips",
      hashtags: "#BeautyPhotography"
    },

    // April
    '04-03': {
      title: "Find a Rainbow Day",
      content: "Colorful hair transformations",
      hashtags: "#RainbowHair"
    },
    '04-10': {
      title: "Siblings Day",
      content: "Family discount packages",
      hashtags: "#FamilyBeauty"
    },
    '04-16': {
      title: "Wear PJs to Work Day",
      content: "Comfortable salon wear ideas",
      hashtags: "#SalonStyle"
    },
    '04-22': {
      title: "Earth Day",
      content: "Eco-friendly salon practices",
      hashtags: "#SustainableBeauty"
    },
    '04-30': {
      title: "Hairstylist Appreciation Day",
      content: "Team spotlights, behind-the-scenes, client testimonials",
      hashtags: "#HairstylistLife #SalonAppreciation #BeautyPro"
    }
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection) => {
    setMonthIndex((prevIndex) => {
      const nextIndex = prevIndex + newDirection;
      if (nextIndex < 0) return months.length - 1;
      if (nextIndex >= months.length) return 0;
      return nextIndex;
    });
  };

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  const eventVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  const getDaysInMonth = (monthIdx) => {
    return new Date(2025, monthIdx + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (monthIdx) => {
    return new Date(2025, monthIdx, 1).getDay();
  };

  const getMonthDates = (monthIdx) => {
    const dates = [];
    const firstDay = getFirstDayOfMonth(monthIdx);
    const daysInMonth = getDaysInMonth(monthIdx);
    
    // Add empty cells for days before the first of the month
    for (let i = 0; i < firstDay; i++) {
      dates.push({ day: null });
    }
    
    // Add the days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      const dateKey = `${String(monthIdx + 1).padStart(2, '0')}-${String(i).padStart(2, '0')}`;
      const event = beautyEvents[dateKey];
      dates.push({ day: i, event });
    }
    return dates;
  };

 return (
   <div className="w-full min-h-screen overflow-y-auto bg-gradient-to-b from-teal-50 to-white">
     <div className="relative w-full pb-32">
       <div className="p-4">
         <Button
           leftIcon={<FaArrowLeft />}
           onClick={() => setShowCalendar(false)}
           size="sm"
           mb={4}
         >
           Back to Social
         </Button>

         <AnimatePresence initial={false} custom={monthIndex}>
           <motion.div
             key={monthIndex}
             custom={monthIndex}
             variants={variants}
             initial="enter"
             animate="center"
             exit="exit"
             transition={{
               x: { type: "spring", stiffness: 300, damping: 30 },
               opacity: { duration: 0.2 }
             }}
             drag="x"
             dragConstraints={{ left: 0, right: 0 }}
             dragElastic={1}
             onDragEnd={(e, { offset, velocity }) => {
               const swipe = swipePower(offset.x, velocity.x);
               if (swipe < -swipeConfidenceThreshold) {
                 paginate(1);
               } else if (swipe > swipeConfidenceThreshold) {
                 paginate(-1);
               }
             }}
             className="w-full p-4"
           >
             <div className="text-3xl font-bold text-center mb-8 text-gray-800">
               {months[monthIndex]} 2025
             </div>
             
             <div className="grid grid-cols-7 gap-1 mb-2">
               {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => (
                 <div key={day} className="text-center text-sm font-semibold text-gray-600">
                   {day}
                 </div>
               ))}
             </div>

             <div className="grid grid-cols-7 gap-1">
               {getMonthDates(monthIndex).map(({ day, event }, index) => (
                 <motion.div
                   key={index}
                   whileTap={{ scale: 0.95 }}
                   onClick={() => event && setSelectedEvent(event)}
                   className={`aspect-square p-2 rounded-lg ${
                     day === null ? 'bg-transparent' :
                     event ? 'bg-gradient-to-br from-teal-100 to-pink-50 cursor-pointer' : 'bg-white'
                   } shadow-sm`}
                 >
                   {day && (
                     <>
                       <div className="text-sm font-medium text-gray-800">{day}</div>
                       {event && (
                         <div className="mt-1">
                           <div className="text-xs font-medium text-teal-600 truncate">
                             {event.title}
                           </div>
                         </div>
                       )}
                     </>
                   )}
                 </motion.div>
               ))}
             </div>
           </motion.div>
         </AnimatePresence>

         <AnimatePresence>
           {selectedEvent && (
             <motion.div
               initial="hidden"
               animate="visible"
               exit="hidden"
               variants={eventVariants}
               className="fixed bottom-0 left-0 right-0 max-h-[60vh] overflow-y-auto bg-white p-6 rounded-t-3xl shadow-lg z-50"
             >
               <div className="mb-4">
                 <div className="text-xl font-bold text-gray-800 mb-2">
                   {selectedEvent.title}
                 </div>
                 <div className="text-gray-600 mb-2">
                   {selectedEvent.content}
                 </div>
                 <div className="text-teal-600 text-sm">
                   {selectedEvent.hashtags}
                 </div>
               </div>
               <motion.button
                 whileTap={{ scale: 0.95 }}
                 onClick={() => setSelectedEvent(null)}
                 className="w-full bg-teal-500 text-white py-2 rounded-lg font-medium"
               >
                 Close
               </motion.button>
             </motion.div>
           )}
         </AnimatePresence>
       </div>
     </div>
   </div>
 );
};

export default ModernCalendar;
