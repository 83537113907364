// src/Components/LiveKit/AudioRoom.js
import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import {
  LiveKitRoom,
  RoomAudioRenderer,
  BarVisualizer,
  VoiceAssistantControlBar,
  useVoiceAssistant,
  DisconnectButton,
} from '@livekit/components-react';
import '@livekit/components-styles';
import { Box, Button, Text, Flex } from '@chakra-ui/react';
import { useAuth } from '../../context/AuthContext';
import './AudioRoom.css';

// Close icon component
const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

function SimpleVoiceAssistant({ onStateChange, onResponse }) {
  const { state, audioTrack, lastResponse } = useVoiceAssistant();

  useEffect(() => {
    if (onStateChange && state) {
      console.log('Voice Assistant State:', state);
      onStateChange(state);
    }
  }, [onStateChange, state]);

  useEffect(() => {
    if (lastResponse && onResponse) {
      console.log('Last Response:', lastResponse);
      let processedResponse = null;
      try {
        processedResponse = JSON.parse(lastResponse);
      } catch (e) {
        console.error('Failed to parse lastResponse:', e);
        processedResponse = { text_content: lastResponse };
      }
      onResponse(processedResponse);
    }
  }, [lastResponse, onResponse]);

  if (!state || !audioTrack) {
    return (
      <Text textAlign="center" color="gray.500">
        Initializing voice assistant...
      </Text>
    );
  }

  return (
    <Box h="96" maxW="90vw" mx="auto" data-lk-agent-state={state}>
      <BarVisualizer
        state={state}
        barCount={5}
        trackRef={audioTrack}
        className="agent-visualizer"
        options={{ minHeight: 24 }}
      />
    </Box>
  );
}

function VoiceControls({ onDisconnect, agentState }) {
  return (
    <Flex align="center" justify="center" gap="4">
      <VoiceAssistantControlBar controls={{ leave: false }} />
      <DisconnectButton onClick={onDisconnect}>
        <CloseIcon />
      </DisconnectButton>
    </Flex>
  );
}

const AudioRoom = () => {
  const [token, setToken] = useState('');
  const [wsUrl, setWsUrl] = useState('');
  const [roomName, setRoomName] = useState('');
  const [error, setError] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [agentState, setAgentState] = useState("disconnected");
  const [response, setResponse] = useState(null);
  const { isAuthenticated, user } = useAuth();
  const hasFetchedToken = useRef(false);

  useEffect(() => {
    const fetchToken = async () => {
      if (hasFetchedToken.current) {
        console.log('Token fetch already initiated.');
        return;
      }
      hasFetchedToken.current = true;

      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://sbscoaches.com';
        const response = await fetch(`${backendUrl}/api/livekit/token`, {
          method: 'GET',
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error(`Failed to get token: ${response.statusText}`);
        }
        
        const data = await response.json();
        console.log('Token data received:', data);
        setToken(data.token);
        setWsUrl(data.ws_url);
        setRoomName(data.room);
      } catch (err) {
        setError(err.message || 'Failed to get token');
        console.error('Token fetch error:', err);
      }
    };

    if (isAuthenticated && user) {
      fetchToken();
    }
  }, [isAuthenticated, user]);

  const handleRoomConnected = () => {
    console.log('Connected to LiveKit room:', roomName);
    setIsConnected(true);
  };

  const handleRoomDisconnected = async () => {
    console.log('Disconnected from LiveKit room:', roomName);
    setIsConnected(false);
    setAgentState("disconnected");
    setResponse(null);

    if (roomName) {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
        const response = await fetch(`${backendUrl}/api/livekit/rooms/${roomName}`, {
          method: 'DELETE',
          credentials: 'include',
        });
        
        if (!response.ok) {
          console.error('Failed to cleanup room:', await response.text());
        } else {
          console.log('Room cleanup successful.');
        }
      } catch (err) {
        console.error('Error cleaning up room:', err);
      }
    }
  };

  if (!isAuthenticated) {
    return (
      <Box p="4" bg="yellow.100" color="yellow.700" rounded="md">
        Please log in to use the voice assistant.
      </Box>
    );
  }

  if (error) {
    return (
      <Box p="4" bg="red.100" color="red.700" rounded="md">
        Error: {error}
      </Box>
    );
  }

  if (!wsUrl || !token) {
    return (
      <Box p="4" textAlign="center" color="gray.600">
        Initializing voice assistant...
      </Box>
    );
  }

  return (
    <Box h="full">
      <LiveKitRoom
        serverUrl={wsUrl}
        token={token}
        audio={true}
        video={false}
        data-lk-theme="default"
        onConnected={handleRoomConnected}
        onDisconnected={handleRoomDisconnected}
        connect={isConnected}
      >
        <Flex direction="column" align="center" justify="center" h="full" p="4">
          {!isConnected ? (
            <Button
              colorScheme="blue"
              size="lg"
              onClick={() => setIsConnected(true)}
            >
              Start Voice Assistant
            </Button>
          ) : (
            <>
              <SimpleVoiceAssistant
                onStateChange={setAgentState}
                onResponse={response => {
                  console.log('Agent response received:', response);
                  setResponse(response);
                }}
              />
              <VoiceControls
                onDisconnect={() => setIsConnected(false)}
                agentState={agentState}
              />
              <RoomAudioRenderer />
              <AnimatePresence>
                {response && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box mt="4" p="4" bg="white" rounded="md" shadow="md" maxW="md">
                      <Text color="gray.800">{response.text_content}</Text>
                    </Box>
                  </motion.div>
                )}
              </AnimatePresence>
            </>
          )}
        </Flex>
      </LiveKitRoom>
    </Box>
  );
};

export default AudioRoom;
