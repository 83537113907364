import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Text,
  useToast,
  VStack,
  Icon,
  Stack,
  Progress,
  Card,
  CardBody,
  Divider,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FaCreditCard, FaCoins } from 'react-icons/fa';

const SubscriptionManagement = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  const [purchasingBundle, setPurchasingBundle] = useState(false);

  // Fetch token status
  useEffect(() => {
    fetchTokenStatus();
  }, []);

  const fetchTokenStatus = async () => {
    try {
      const response = await axios.get('https://sbscoaches.com/api/livekit/token_usage', {
        withCredentials: true
      });
      setTokenData(response.data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch token status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleManageSubscription = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://sbscoaches.com/api/customer-portal', {}, 
        { withCredentials: true }
      );
      window.location.href = response.data.url;
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Error',
        description: 'Failed to access the customer portal.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePurchaseBundle = async (bundleType) => {
    setPurchasingBundle(true);
    try {
      const response = await axios.post(
        'https://sbscoaches.com/api/token/purchase-bundle',
        { bundle_type: bundleType },
        { withCredentials: true }
      );
      window.location.href = response.data.checkout_url;
    } catch (error) {
      setPurchasingBundle(false);
      toast({
        title: 'Error',
        description: 'Failed to initiate bundle purchase.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBack = () => {
    navigate('/user-profile');
  };

  const calculateTokenPercentage = () => {
    if (!tokenData) return 0;
    const { available_tokens, monthly_allocation } = tokenData;
    return (available_tokens / monthly_allocation) * 100;
  };

  const getTokenStatus = () => {
    const percentage = calculateTokenPercentage();
    if (percentage <= 15) return { color: 'red', message: 'Low Tokens' };
    if (percentage <= 30) return { color: 'yellow', message: 'Running Low' };
    return { color: 'green', message: 'Healthy' };
  };

  return (
    <Box maxWidth="800px" margin="0 auto" p={8}>
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        size="sm"
        mb={4}
        onClick={handleBack}
      >
        Return to Profile
      </Button>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
        {/* Subscription Management */}
        <Card>
          <CardBody>
            <VStack spacing={4} align="stretch">
              <Icon as={FaCreditCard} w={8} h={8} color="blue.500" />
              <Heading as="h3" size="md">
                Subscription
              </Heading>
              <Button
                colorScheme="teal"
                onClick={handleManageSubscription}
                isLoading={loading}
              >
                Manage Subscription
              </Button>
            </VStack>
          </CardBody>
        </Card>

        {/* Token Management */}
        <Card>
          <CardBody>
            <VStack spacing={4} align="stretch">
              <Icon as={FaCoins} w={8} h={8} color="yellow.500" />
              <Heading as="h3" size="md">
                Voice AI Tokens
              </Heading>
              
              {tokenData && (
                <>
                  <Stat>
                    <StatLabel>Available Tokens</StatLabel>
                    <StatNumber>{tokenData.available_tokens.toLocaleString()}</StatNumber>
                    <StatHelpText color={getTokenStatus().color}>
                      {getTokenStatus().message}
                    </StatHelpText>
                  </Stat>

                  <Progress
                    value={calculateTokenPercentage()}
                    colorScheme={getTokenStatus().color}
                    size="sm"
                    mb={4}
                  />

                  <Divider my={4} />

                  <Stack spacing={4}>
                    <Button
                      colorScheme="purple"
                      onClick={() => handlePurchaseBundle('small')}
                      isLoading={purchasingBundle}
                    >
                      Purchase Small Bundle (180,000 tokens)
                    </Button>
                    <Button
                      colorScheme="purple"
                      variant="outline"
                      onClick={() => handlePurchaseBundle('large')}
                      isLoading={purchasingBundle}
                    >
                      Purchase Large Bundle (600,000 tokens)
                    </Button>
                  </Stack>
                </>
              )}
            </VStack>
          </CardBody>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default SubscriptionManagement;
