// Performance component updated to pass correct data
import React, { useEffect, useCallback } from 'react';
import { Box, Heading, VStack, Text, Button, Flex } from '@chakra-ui/react';
import { usePerformanceData } from '../hooks/usePerformanceData';
import PerformanceSummary from './PerformanceSummary';
import PerformanceChart from './PerformanceChart';
import AIFeedback from './AIFeedback';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import SwipeableViews from 'react-swipeable-views';

const Performance = ({ onShowIntegrations }) => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const {
    performanceData,
    aiAnalysis,
    loading,
    error,
    refreshData,
    isSquareIntegrated,
  } = usePerformanceData();

  useEffect(() => {
    console.log("Current states:", { isSquareIntegrated, loading, error, performanceData });
  }, [isSquareIntegrated, loading, error, performanceData]);

  useEffect(() => {
    if (!isAuthenticated) {
      console.warn("User is not authenticated, navigating to login.");
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleShowIntegrations = useCallback(() => {
    console.log("Attempting to show Integrations");
    if (onShowIntegrations) {
      onShowIntegrations();
    } else {
      console.error("onShowIntegrations function not provided");
    }
  }, [onShowIntegrations]);

  const handleNavigateToDataEntry = async () => {
    try {
      if (!user.spreadsheet_id) {
        throw new Error('No spreadsheetId found in user data');
      }

      const response = await fetch('https://sbscoaches.com/api/enter-data', {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Navigating to data entry with spreadsheetId:", user.spreadsheet_id, "and next_row:", data.next_row);
        navigate('/data-entry', {
          state: { 
            spreadsheetId: user.spreadsheet_id,
            next_row: data.next_row 
          }
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to prepare for data entry');
      }
    } catch (error) {
      console.error('Error navigating to data entry:', error);
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  if (loading) {
    return <Text>Loading performance data for last 7 days and for last month...</Text>;
  }

  if (isSquareIntegrated === false) {
    return (
      <Box p={4} textAlign="center">
        <Text mb={4}>
          Hello {user?.name}, you are not currently integrated with Square. Would you like to connect your Square account or enter data manually?
        </Text>
        <Button onClick={handleShowIntegrations} colorScheme="blue" mr={4}>
          Connect Square Account
        </Button>
        <Button onClick={handleNavigateToDataEntry} colorScheme="green">
          Enter Data Manually
        </Button>
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4} textAlign="center">
        <Text color="red.500" mb={4}>{error}</Text>
        <Button onClick={refreshData} colorScheme="blue">
          Retry
        </Button>
      </Box>
    );
  }

  const timePeriods = ['weekly', 'monthly'];

  return (
    <Box p={2}>
      <Heading size="md" mb={4}>Performance Review for {user?.name}</Heading>

      {performanceData ? (
        <SwipeableViews enableMouseEvents>
          {timePeriods.map((period) => {
            const locationData = performanceData[period]["Michael's Salon Loft Experience"];
            const aiData = aiAnalysis[period];
            return (
              <VStack key={period} spacing={6} align="stretch" p={4}>
                <PerformanceSummary data={locationData} period={period} />
                <PerformanceChart data={locationData} period={period} />
                <AIFeedback data={{ ai_analysis: aiData }} period={period} />
              </VStack>
            );
          })}
        </SwipeableViews>
      ) : (
        <Box textAlign="center">
          <Text>No performance data available. Please try refreshing or contact support.</Text>
        </Box>
      )}

      <Flex justifyContent="center" mt={8}>
        <Button colorScheme="blue" onClick={refreshData} mr={4}>
          Refresh Data
        </Button>
      </Flex>
    </Box>
  );
};

export default Performance;
